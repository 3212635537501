import {
  CssBaseline,
  MuiThemeProvider,
  Container,
  createTheme,
} from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./pages/home/HomePage";
import Footer from "./components/Footer";
import "./App.css";
import PageNotFound from "./pages/404/404Page";
const font = "'Karla', sans-serif";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: font,
  },
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <NavBar />

      <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route
            component={() => (
              <Container maxWidth="md">
                <PageNotFound />
              </Container>
            )}
          />
        </Switch>
      </Router>

      <Footer />
    </MuiThemeProvider>
  );
}
export default App;
