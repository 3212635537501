import { Button } from "@material-ui/core";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import logo from "./clbrk_logo.svg";
import { IoOpenOutline } from "react-icons/io5";

interface GameModel {
  title: string;
  imageUrl: string;
  learnMoreLink: string;
}
function Games() {
  const data: GameModel[] = [
    {
      title: "Callbreak Multiplayer",
      imageUrl: logo,
      learnMoreLink: "https://callbreakmultiplayer.com/",
    },
  ];
  return (
    <Container
      style={{
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              margin: "16px",
            }}
          >
            Our Games
          </Typography>
        </Grid>

        <Grid
          item
          container
          spacing={2}
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {data.map((item: GameModel) => (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
              <Card elevation={0}>
                <CardContent>
                  <Grid
                    container
                    spacing={4}
                    direction="column"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <img
                        alt={item.imageUrl}
                        src={item.imageUrl}
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "16px",
                        }}
                      ></img>
                    </Grid>

                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1.6rem",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Button href={item.learnMoreLink} target="_blank">
                        <IoOpenOutline
                          style={{
                            marginRight: "8px",
                          }}
                        />
                        Learn More
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Games;
