import React from "react";
import { AppBar, Toolbar, Button, Container } from "@material-ui/core";
import bigLogo from "../assets/logo_max.svg";
import { Grid } from "@material-ui/core";

function NavBar() {
  return (
    <AppBar position="relative" elevation={0}>
      <Toolbar>
        <Container maxWidth="md">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button href="/">
                <img
                  src={bigLogo}
                  width="200px"
                  style={{
                    margin: "16px",
                  }}
                  alt="Logo"
                ></img>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
