import React from "react";
import { Grid } from "@material-ui/core";
import googlePlay from "../assets/googlePlay.svg";
import AppStore from "../assets/appStore.svg";

function DownloadButtons() {
  return (
    <Grid item container direction="row" alignItems="center" justify="center">
      <Grid item>
        <a
          href="https://play.google.com/store/apps/dev?id=5981158972755627254"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src={googlePlay}
            style={{
              height: "2.5rem",
            }}
          />
        </a>
      </Grid>

      <Grid item>
        <a
          href="https://apps.apple.com/us/app/callbreak-multi-player-game/id1565145115"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={AppStore}
            alt="Download on the App Store"
            style={{
              height: "2.5rem",
              marginLeft: "1rem",
            }}
          />
        </a>
      </Grid>
    </Grid>
  );
}

export default DownloadButtons;
