import React from "react";
import { Container, Grid } from "@material-ui/core";
import Games from "./games/Games";
import TextLoop from "react-text-loop";

function HomePage() {
  return (
    <div>
      <div
        style={{
          height: "70vh",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "70vh",
            backgroundColor: "green",
            opacity: 0.08,
            zIndex: -1,
          }}
        ></div>
        <Container maxWidth="xs">
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={3}
            justify="center"
            style={{
              height: "70vh",
              width: "100%",
            }}
          >
            <Grid item>
              <CustomText label="We" />
            </Grid>
            <Grid item>
              <div
                style={{
                  color: "#4182e4",
                  fontSize: "3rem",
                  fontWeight: "bold",
                }}
              >
                <TextLoop
                  noWrap={false}
                  children={[
                    "build Games",
                    "create Experiences",
                    "bring People together",
                    "build Friendships",
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Games />
    </div>
  );
}

function CustomText(props: { label: string }) {
  return (
    <span
      style={{
        color: "#000000",
        fontSize: "3rem",
        fontWeight: "bold",
      }}
    >
      {props.label}
    </span>
  );
}

export default HomePage;
