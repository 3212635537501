import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import DownloadButtons from "./DownloadButtons";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { IconContext } from "react-icons";
import { IconButton } from "@material-ui/core";

function Footer() {
  return (
    <div
      style={{
        background: "#000000",
        paddingBottom: "16px",
      }}
    >
      <Container
        maxWidth="md"
        style={{
          marginBottom: "16px",
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              style={{
                color: "white",
                fontWeight: "bold",
                marginTop: "16px",
              }}
            >
              Follow us on
            </Typography>
            <SocialMediaButtons />
          </Grid>

          <Grid item>
            <DownloadButtons />
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            height: "1px",
            marginTop: "16px",
            marginBottom: "16px",
            background: "#fafafa",
          }}
        />

        <Typography
          style={{
            color: "white",
            marginTop: "16px",
            alignSelf: "center",
          }}
        >
          Copyright ©2021 Khellabs
        </Typography>
      </Container>
    </div>
  );
}

interface ScocialMediaLink {
  link: string;
  icon: any;
}
function SocialMediaButtons() {
  const data: ScocialMediaLink[] = [
    { link: "https://www.facebook.com/khellabs", icon: <AiFillFacebook /> },
    {
      link: "https://www.instagram.com/khellabs",
      icon: <AiOutlineInstagram />,
    },
    {
      link: "https://www.twitter.com/khel_labs",
      icon: <AiOutlineTwitter />,
    },
  ];
  return (
    <IconContext.Provider
      value={{
        color: "white",
        className: "global-class-name",
        size: "24px",
      }}
    >
      <Grid container>
        {data.map((item: ScocialMediaLink) => (
          <Grid item key={item.link}>
            <IconButton href={item.link} target="_blank">
              {item.icon}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </IconContext.Provider>
  );
}
export default Footer;
